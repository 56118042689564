.eunomia-neutral {
  //color: $eunomia-neutral;
  color: white;
}

.eunomia-positive {
  color: $eunomia-positive;
}

.eunomia-negative {
  color: $eunomia-negative;
}

.eunomia-background-neutral {
  color: #1b1b1b;
  background-color: white;
}

.eunomia-background-positive {
  color: #1b1b1b;
  background-color: $eunomia-positive-alt;
}

.eunomia-background-negative {
  color: #1b1b1b;
  background-color: $eunomia-negative-alt;
}

.eunomia-trust-button {
  background-color: #4fe77d;
  color: #222;
}

.eunomia-no-trust-button {
  background-color: #e66cff;
  color: #1b1b1b;
}
