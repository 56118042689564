$eunomia-primary: $eunomia-light;
$eunomia-secondary: $eunomia-dark;
$eunomia-action-bar-wrapper: #eff2f5;
$eunomia-wrapper: #eff2f5;
$eunomia-indicators-text: #111;
$eunomia-cascade-action-bar-wrapper: #eff3f5;
$motd: $light-text-color;
$nudge-border: #626262;
$cascade-post-text:  #333;
$logo_path: "/eunomia/static/logo_light.png";
